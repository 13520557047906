<template>
  <div>
    <div v-if="getValidData" class="playersContainer">
      <search-player-card v-for="(item) in this.GET_FULL_RESPONSE_TYPE" :key="item.id" :item="item" />
    </div>

    <div v-else-if="getEmptyData" class="noContent">
      <span class="noContent__text">Игроки не найдены :'(</span>
    </div>

    <div v-else-if="this.GET_STATUS_LOADING" class="spinnerBlock">
      <spinner />
    </div>

    <div v-if="getUsersStatusLoad" class="triggerForLoadingUsers" ref="triggerForLoading">
      <spinner />
    </div>
  </div>

</template>
<script>

import SearchPlayerCard from "@/components/searchScreen/searchPlayersContent/searchPlayerCard/searchPlayerCard";
import Spinner from "@/components/UIElements/spinner";
import {mapActions, mapGetters} from "vuex";
import isElementInViewport from "@/otherJS/isElementInViewport";

export default {
  name: "searchPlayersContent",
  components: {Spinner, SearchPlayerCard},
  data() {
    return {
      observer: null,
    }
  },
  computed: {
    ...mapGetters([
        'GET_SEARCH_TAB',
        'GET_FULL_SEARCH_RESPONSE',
        'GET_FULL_RESPONSE_TYPE',
        'GET_STATUS_LOADING',
        'GET_INPUT_SEARCH_TEXT'
    ]),

    getEmptyData() {
      return this.getFullSearchResponsesIsArray && this.GET_FULL_SEARCH_RESPONSE.users.data?.length === 0
    },

    getValidData() {
      return this.getFullSearchResponsesIsArray && this.GET_FULL_SEARCH_RESPONSE.users.data?.length !== 0
    },

    getFullSearchResponsesIsArray() {
      return Array.isArray(this.GET_FULL_SEARCH_RESPONSE.users.data)
    },

    getUsersStatusLoad() {
      return this.GET_FULL_SEARCH_RESPONSE.users.scrollDataIsLoading && this.GET_INPUT_SEARCH_TEXT
    }
  },

  methods: {
    ...mapActions([
        'SET_ADD_SEARCH_TYPE_BY_SCROLLING',
        'SET_SCROLL_LOADING_FALSE'
    ]),

    fetchMoreData() {
      return this.SET_ADD_SEARCH_TYPE_BY_SCROLLING()
    },

    isElementInViewportPlayers() {
      const redBlock = this.$refs.triggerForLoading;
      return isElementInViewport(redBlock);
    },
  },

  mounted() {

    const redBlock = document.querySelector('.triggerForLoadingUsers');

    if (redBlock) {
      const options = {
        root: null,
        threshold: 0,
      }

      this.observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.fetchMoreData()
          }
        });
      }, options);
      this.observer.observe(redBlock)
    }
  },

  watch: {
    GET_FULL_SEARCH_RESPONSE() {
      if (this.GET_FULL_SEARCH_RESPONSE.users.data && this.isElementInViewportPlayers()) {
        this.fetchMoreData()
      }
    },
  },

  destroyed() {
    if (this.observer) this.observer.disconnect();
  },
}

</script>

<style lang="scss" scoped>
  .playersContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    column-gap: 16px;
    row-gap: 8px;
  }

  .spinnerBlock {
    width: 100%;
    height: 50vh;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .noContent {
    width: 100%;
    height: 50vh;

    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      color: white;
      font-size: 22px;
      // line-height: 16.8px;
      font-family: 'SF UI Text', serif;
      font-weight: normal;
    }
  }

  .triggerForLoading {

  }

@media all and (max-width: 1000px) {
  .playersContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media all and (max-width: 810px) {
  .playersContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media all and (max-width: 586px) {
  .playersContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}




</style>