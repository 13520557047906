<template>
  <router-link :to="{name: 'another-profile', params: {id: item.id}}" @click.native="goToUserProfile" class="userCard">
    <div class="leftSide">
      <div class="avatarWrapper">
        <img :src="item.logoUrl" alt="not found"/>
      </div>

      <span class="leftSide__text">{{ item.attributes.login }}</span>
    </div>

    <div class="rightSide">
      <div class="svgWrapper">
        <country-flag :country="item.attributes.country" size="normal" rounded/>
      </div>
    </div>
  </router-link>
</template>

<script>
import CountryFlag from "vue-country-flag";
import {mapActions} from "vuex";

export default {
  name: "searchPlayerCard",
  props: ['item'],
  components: {CountryFlag},
  methods: {
    ...mapActions([
      'SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN',
    ]),
    goToUserProfile() {

      this.SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN();
    }
  }
}
</script>

<style lang="scss" scoped>
.userCard {
  cursor: pointer;
  padding: 8px 16px;
  background-color: #3A3A3A;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;


  .leftSide {
    display: flex;
    flex-direction: row;
    align-items: center;

    .avatarWrapper {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__text {
      margin-left: 8px;
      color: white;
      font-size: 14px;
      line-height: 17px;
      font-family: 'SF UI Text', serif;
    }
  }

  .rightSide {
    .svgWrapper {

    }
  }

  &:hover {
    background-color: lighten(#3A3A3A, 10);
  }
}
</style>
