<template>
  <div class="search">
    <div class="container">
      <div class="searchHeader">
        <div class="searchHeaderLeft">
          <span class="searchHeaderLeft__mainText">Результаты по запросу «<span class="searchHeaderLeft__secondaryText">
            {{ this.GET_MEMOIZED_INPUT_SEARCH_TEXT }}</span>» </span>
        </div>

        <div class="searchHeaderCenter">
          <div class="tab" @click="tabClick('users')">
            <span class="tab__text" :class="{'tab__text_active': GET_SEARCH_TAB === 'users'}">ИГРОКИ</span>
            <div class="tab__cloud cloud">
              <span class="cloud__count">{{ getCountOfUsers }}</span>
            </div>
          </div>
          <div class="tab" @click="tabClick('teams')">
            <span class="tab__text" :class="{'tab__text_active': GET_SEARCH_TAB === 'teams'}">КОМАНДЫ</span>
            <div class="tab__cloud cloud">
              <span class="cloud__count">{{ getCountOfTeams }}</span>
            </div>
          </div>
          <div class="tab" @click="tabClick('tournaments')">
            <span class="tab__text" :class="{'tab__text_active': GET_SEARCH_TAB === 'tournaments'}">ТУРНИРЫ</span>
            <div class="tab__cloud cloud">
              <span class="cloud__count">{{ getCountOfTournaments }}</span>
            </div>
          </div>
        </div>

      </div>

      <div class="searchBody">
        <div v-if="this.GET_SEARCH_TAB === 'users'">
          <search-players-content/>
        </div>

        <div v-if="this.GET_SEARCH_TAB === 'teams'">
          <search-teams-content/>
        </div>

        <div v-if="this.GET_SEARCH_TAB === 'tournaments'">
          <search-tournaments-content/>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import SearchPlayersContent from "@/components/searchScreen/searchPlayersContent/searchPlayersContent";
import SearchTeamsContent from "@/components/searchScreen/searchTeamsContent/searchTeamsContent";
import SearchTournamentsContent from "@/components/searchScreen/searchTournamentsContent/searchTournamentsContent";
import {mapActions, mapGetters} from "vuex";
import Spinner from "@/components/UIElements/spinner";

export default {
  name: "search-page",
  components: {SearchTournamentsContent, SearchTeamsContent, SearchPlayersContent},
  methods: {
    ...mapActions([
      'SET_SEARCH_TAB',
      'SET_ADD_SEARCH_USERS_BY_SCROLLING',
      'SET_ADD_SEARCH_TEAMS_BY_SCROLLING',
      'SET_ADD_SEARCH_TOURNAMENTS_BY_SCROLLING'
    ]),
    tabClick(status) {
      this.SET_SEARCH_TAB(status);
    },

  },
  computed: {
    ...mapGetters([
      'GET_SEARCH_TAB',
      'GET_FULL_SEARCH_RESPONSE',
      'GET_MEMOIZED_INPUT_SEARCH_TEXT'
    ]),

    getCountOfUsers() {
      return this.GET_FULL_SEARCH_RESPONSE.users?.meta?.page?.total ?? '0'
    },

    getCountOfTeams() {
      return this.GET_FULL_SEARCH_RESPONSE.teams?.meta?.page?.total ?? '0'
    },

    getCountOfTournaments() {
      return this.GET_FULL_SEARCH_RESPONSE.tournaments?.meta?.page?.total ?? '0'
    },
  },
}
</script>

<style lang="scss" scoped>

.search {
  margin-top: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .container {
    margin-top: 27px;
  }

  .searchHeader {
    position: sticky;
    top: 80px;
    z-index: 1;
    margin-top: 27px;
    background-color: #3F3F3F;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;

    .searchHeaderLeft {
      display: flex;
      flex: .5;
      flex-direction: row;
      align-items: center;

      &__mainText {
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: white;
        font-family: 'SF UI Text', serif;
        font-weight: normal;
      }

      &__secondaryText {
        color: var(--tournament-color);
      }
    }

    .searchHeaderCenter {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;

      .tab {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 34px;

        &__text {
          font-family: 'SF UI Text', serif;
          line-height: 16.8px;
          font-size: 14px;
          font-weight: normal;
          text-transform: uppercase;
          color: white;
          opacity: .5;
          transition: opacity .2s ease-in-out, color .2s ease-in-out;

          &_active {
            color: var(--tournament-color);
            opacity: 1;
          }
        }

        &__cloud, .cloud {
          display: flex;
          justify-content: center;
          align-items: center;

          background-color: #272727;
          margin-left: 4px;
          border-radius: 30px;
          padding: 6px 14px;

          &__count {
            color: white;
            text-transform: uppercase;
            font-size: 10px;
            line-height: 12px;
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &:hover {
          .tab__text {
            transition: opacity .2s ease-in-out, color .2s ease-in-out;
            opacity: 1;
            color: var(--tournament-color);
          }
        }
      }
    }
  }

  .searchBody {
    padding-bottom: 100px;
    margin-top: 16px;
  }

  .triggerForLoading {

  }
}

@media all and (max-width: 750px) {
  .search {
    .searchHeader {
      flex-direction: column;

      .searchHeaderCenter {
        margin-top: 15px;
      }
    }
  }
}

@media all and (max-width: 520px) {
  .search {
    .searchHeader {
      .searchHeaderCenter {
        width: 100%;
        justify-content: space-around;

        .tab {
          margin-left: 0;

          &__cloud, .cloud {
            padding: 6px 10px;
          }

          &__text {
            font-size: 12px;
          }
        }
      }
    }
  }
}

</style>
