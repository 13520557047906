<template>
  <div>
    <div v-if="getValidData" class="tournamentContainer">
      <search-tournament-card v-for="item in this.GET_FULL_RESPONSE_TYPE" :item="item" :key="item.id"/>
    </div>

    <div v-else-if="getEmptyData" class="noContent">
      <span class="noContent__text">Турниры не найдены :'(</span>
    </div>

    <div v-else-if="this.GET_STATUS_LOADING" class="spinnerBlock">
      <spinner />
    </div>

    <div v-if="getUsersStatusLoad" class="triggerForLoadingTournaments" ref="triggerForLoading">
      <spinner />
    </div>
  </div>
</template>

<script>
import SearchTournamentCard
  from "@/components/searchScreen/searchTournamentsContent/searchTournamentCard/searchTournamentCard";
import {mapActions, mapGetters} from "vuex";
import Spinner from "@/components/UIElements/spinner";
import isElementInViewport from "@/otherJS/isElementInViewport";

export default {
  name: "searchTournamentsContent",
  components: {Spinner, SearchTournamentCard},
  data() {
    return {
      observer: null,
    }
  },
  methods: {
    ...mapActions([
        'SET_ADD_SEARCH_TYPE_BY_SCROLLING',
        'SET_SCROLL_LOADING_FALSE'
    ]),

    fetchMoreData() {
      return this.SET_ADD_SEARCH_TYPE_BY_SCROLLING()
    },

    isElementInViewportTournaments() {
      const redBlock = this.$refs.triggerForLoading;
      return isElementInViewport(redBlock);
    },
  },
  computed: {
    ...mapGetters([
      'GET_FULL_SEARCH_RESPONSE',
      'GET_STATUS_LOADING',
      'GET_SEARCH_TAB',
      'GET_FULL_RESPONSE_TYPE',
    ]),

    getUsersStatusLoad() {
      return this.GET_FULL_SEARCH_RESPONSE.tournaments.scrollDataIsLoading
    },

    getEmptyData() {
      return this.getFullSearchResponsesIsArray && this.GET_FULL_SEARCH_RESPONSE.tournaments.data?.length === 0
    },

    getValidData() {
      return this.getFullSearchResponsesIsArray && this.GET_FULL_SEARCH_RESPONSE.tournaments.data?.length !== 0
    },

    getFullSearchResponsesIsArray() {
      return Array.isArray(this.GET_FULL_SEARCH_RESPONSE.tournaments.data)
    }
  },

  mounted() {

    const redBlock = document.querySelector('.triggerForLoadingTournaments');

    if (redBlock) {
      const options = {
        root: null,
        threshold: 0,
      }

      this.observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.fetchMoreData()
          }
        });
      }, options);
      this.observer.observe(redBlock)
    }
  },

  watch: {
    GET_FULL_SEARCH_RESPONSE() {
      if (this.GET_FULL_SEARCH_RESPONSE.tournaments.data && this.isElementInViewportTournaments()) {
        this.fetchMoreData()
      }
    },
  },

  destroyed() {
    if (this.observer) this.observer.disconnect();
  },
}
</script>

<style lang="scss" scoped>
.tournamentContainer {
  display: flex;
  flex-direction: column;
}

.spinnerBlock {
  width: 100%;
  height: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.noContent {
  width: 100%;
  height: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;

  &__text {
    color: white;
    font-size: 22px;
    // line-height: 16.8px;
    font-family: 'SF UI Text', serif;
    font-weight: normal;
  }
}
</style>