<template>
  <div>
    <div v-if="getValidData" class="teamsContainer">
      <search-team-card v-for="item in this.GET_FULL_RESPONSE_TYPE" :item="item" :key="item.id" />
    </div>

    <div v-else-if="getEmptyData" class="noContent">
      <span class="noContent__text">Команды не найдены :'(</span>
    </div>

    <div v-else-if="this.GET_STATUS_LOADING" class="spinnerBlock">
      <spinner />
    </div>

    <div v-if="getTeamsStatusLoad" class="triggerForLoadingTeams" ref="triggerForLoading">
      <spinner />
    </div>
  </div>
</template>

<script>
import SearchTeamCard from "@/components/searchScreen/searchTeamsContent/searchTeamCard/searchTeamCard";
import {mapActions, mapGetters} from "vuex";
import Spinner from "@/components/UIElements/spinner";
import isElementInViewport from "@/otherJS/isElementInViewport";

export default {
  name: "searchTeamsContent",
  components: {Spinner, SearchTeamCard},
  data() {
    return {
      observer: null,
    }
  },
  computed: {
    ...mapGetters([
        'GET_SEARCH_TAB',
        'GET_FULL_RESPONSE_TYPE',
        'GET_FULL_SEARCH_RESPONSE',
        'GET_STATUS_LOADING'
    ]),

    getTeamsStatusLoad() {
      return this.GET_FULL_SEARCH_RESPONSE.teams.scrollDataIsLoading
    },

    getEmptyData() {
      return this.getFullSearchResponsesIsArray && this.GET_FULL_SEARCH_RESPONSE.teams.data?.length === 0
    },

    getValidData() {
      return this.getFullSearchResponsesIsArray && this.GET_FULL_SEARCH_RESPONSE.teams.data?.length !== 0
    },

    getFullSearchResponsesIsArray() {
      return Array.isArray(this.GET_FULL_SEARCH_RESPONSE.teams.data)
    }
  },

  methods: {
    ...mapActions([
      'SET_ADD_SEARCH_TYPE_BY_SCROLLING',
      'SET_SCROLL_LOADING_FALSE'
    ]),

    fetchMoreData() {
      return this.SET_ADD_SEARCH_TYPE_BY_SCROLLING()
    },

    isElementInViewportTeams() {
      const redBlock = this.$refs.triggerForLoading;
      return isElementInViewport(redBlock);
    },
  },

  mounted() {

    const redBlock = document.querySelector('.triggerForLoadingTeams');

    if (redBlock) {
      const options = {
        root: null,
        threshold: 0,
      }

      this.observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.fetchMoreData()
          }
        });
      }, options);
      this.observer.observe(redBlock)
    }
  },

  watch: {
    GET_FULL_SEARCH_RESPONSE() {
      if (this.GET_FULL_SEARCH_RESPONSE.teams.data && this.isElementInViewportTeams()) {
        this.fetchMoreData()
      }
    },
  },

  destroyed() {
    if (this.observer) this.observer.disconnect();
  },
}
</script>

<style lang="scss" scoped>
.teamsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  column-gap: 16px;
  row-gap: 8px;
}

.spinnerBlock {
  width: 100%;
  height: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.noContent {
  width: 100%;
  height: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;

  &__text {
    color: white;
    font-size: 22px;
    // line-height: 16.8px;
    font-family: 'SF UI Text', serif;
    font-weight: normal;
  }
}

@media all and (max-width: 1000px) {
  .teamsContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media all and (max-width: 810px) {
  .teamsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media all and (max-width: 586px) {
  .teamsContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>