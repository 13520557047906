import getMonthName from "@/otherJS/getMonthName";

const parseDate = (myDate) => {
    const date = myDate.getDate();
    const month = getMonthName(myDate.getMonth());
    const year = myDate.getFullYear();

    return `${date} ${month} ${year}г`
}

export default parseDate;